import { useCallback } from 'react'

import { useApolloClient } from '@apollo/client'
import { onExpandUserLoadAreasOfExperience } from 'Features/AreasOfExperience/Graph/onExpandUser'
import { IExploreNode } from 'Features/ExploreQuery/ExploreQuery.types'
import { useFeatureFlag } from 'Features/FeatureFlags/useFeatureFlag'
import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import { useGraphLoaders } from 'Features/GraphNodes/useGraphLoader'
import { onKnowledgeNodeDoubleClick } from 'Features/KnowledgeGraphing/Graph/onKnowledgeNodeDoubleClick'
import communityPathToCommunityUserQuery from 'GraphQL/Queries/Community/communityPathToCommunityUser.graphql'
import communityUserConnectionsByDegreesQuery from 'GraphQL/Queries/Community/communityUserConnectionsByDegrees.graphql'
import getOrganizationQuery from 'GraphQL/Queries/Organization/getOrganization.graphql'
import queryExplorer from 'GraphQL/Queries/QueryExplorer/QueryExplorer.graphql'
import PQueue from 'p-queue'

import isEmpty from 'lodash/isEmpty'
import keys from 'lodash/keys'

import { NODE_KIND, NodeKind } from 'Constants/graph'
import { SEARCH_TYPES } from 'Constants/ids'
import {
  AskOfferStatementKind,
  KnowledgeGraphNodeKind,
} from 'Constants/mainGraphQL'

import { useAppContext, useCommunityContext } from 'Hooks'
import useEventBusSubscribe from 'Hooks/useEventBusSubscribe'
import {
  AppendItemsHandler,
  IGraphQuery,
  IGraphState,
  LoadGraphState,
  TemporaryConnectUserHandler,
} from 'Hooks/useGraphContext'

import { useDashboardContext } from 'Pages/Community/Dashboard/Context'

import EventBus from 'Services/EventBus'

import { IAreaOfExperienceNode, ITagNode } from './utils'

export interface IOptions {
  users: string[]
  skills: string[]
  organizations: string[]
  communities: string[]
  tags: string[]
  knowledge: string[]
  explore: string[]
}

export interface IUseGraphQuery {
  options?: IOptions
  setOptions?: React.Dispatch<React.SetStateAction<IOptions | undefined>>
  setPaths: React.Dispatch<React.SetStateAction<IGraphPersonNode[][]>>
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setGraphState: React.Dispatch<React.SetStateAction<IGraphState>>
  handleAppendItems: AppendItemsHandler
  handleTemporaryConnectUser: TemporaryConnectUserHandler
}

export interface IItem {
  id: string
  label: string
  type?: NodeKind
  photoUrl?: string
  // TODO: refactor this to be more specific
  value?: any
  communities?: MainSchema.Community[]
}

// Only queries for loading items in the background, or loading users, skills, tags, organizations, paths, etc. into the graph
export default function useGraphQuery({
  options,
  setOptions,
  setPaths,
  setIsLoading,
  setGraphState,
  handleAppendItems,
  handleTemporaryConnectUser,
}: IUseGraphQuery): IGraphQuery {
  const { community } = useCommunityContext()
  const { me } = useAppContext()
  const { selectedCommunityIds } = useDashboardContext()
  const client = useApolloClient()
  const { loadPeople, loadPerson, loadTags } = useGraphLoaders()
  const { featureEnabled } = useFeatureFlag()

  const communityId = community?.id

  const handleExpandUser = useCallback(
    async ({
      userId,
      communityId,
    }: {
      userId: string
      communityId: string
    }) => {
      if (!communityId) {
        return
      }

      setIsLoading(true)

      // Load the selected users full profile, users loaded for graphing are only partial profiles
      const expandedPerson = await loadPerson({
        variables: {
          userId,
          communityId,
        },
      })

      const connectedUserIds =
        expandedPerson?.data?.graphPerson?.connections?.map(
          e => e.toCommunityUserId!,
        ) || []

      const userIds = [userId, ...connectedUserIds]

      const result = await loadPeople({
        variables: {
          communityIds: [...selectedCommunityIds, communityId],
          limit: userIds.length,
          userIds,
        },
      })

      const areasOfExperience = (await featureEnabled('areas_of_experience'))
        ? await onExpandUserLoadAreasOfExperience(client, communityId, userId)
        : []

      setIsLoading(false)

      const users =
        (result?.data?.graphPeople?.nodes as IGraphPersonNode[]) || []
      const organizations = [
        ...(expandedPerson?.data?.graphPerson?.communityUserWorkHistory
          ?.filter(workHistory => workHistory.isCurrent)
          ?.map(workHistory => workHistory.organization) ?? []),
      ] as MainSchema.Organization[]

      handleAppendItems({
        users,
        organizations,
        areasOfExperience,
      })
    },
    [
      selectedCommunityIds,
      setIsLoading,
      loadPerson,
      loadPeople,
      featureEnabled,
      client,
      handleAppendItems,
    ],
  )

  const handleSearch = useCallback(
    async ({
      limit = 25,
      users = [],
      skills = [],
      tags = [],
      organizations = [],
      communities = [],
    }: {
      limit?: number
      users?: string[]
      skills?: string[]
      tags?: string[]
      organizations?: string[]
      communities?: string[]
    }) => {
      if (!communityId) {
        return
      }

      setIsLoading(true)

      const result = await loadPeople({
        variables: {
          communityIds: [...communities, communityId],
          limit,
          userIds: users,
          skillIds: skills,
          tagIds: tags,
          workHistoryOrganizationIds: organizations,
          educationHistoryOrganizationIds: organizations,
        },
      })

      setGraphState(prevState => ({
        ...prevState,
        appendUsers:
          (result?.data?.graphPeople?.nodes as IGraphPersonNode[]) || [],
      }))

      setIsLoading(false)
    },
    [communityId, loadPeople, setGraphState, setIsLoading],
  )

  const handleAddOrganization = useCallback(
    async ({
      id,
      isSelected = false,
      isMultiSelect = false,
    }: {
      id: string
      isSelected?: boolean
      isMultiSelect?: boolean
    }) => {
      setIsLoading(true)

      const result = await client.query<
        Pick<MainSchema.Query, 'organization'>,
        MainSchema.QueryOrganizationArgs
      >({
        query: getOrganizationQuery,
        fetchPolicy: 'network-only',
        variables: {
          id,
        },
      })

      const organization = result?.data?.organization

      if (organization) {
        handleAppendItems({
          organizations: [organization],
        })

        // Using timeout here to have an updated state that is handled by handleSearch, this has impact on updateUserSelectionAndActions at useAction.js hook,
        // Without using timeout updateUserSelectionAndActions will have an outdated state because the selection will be updated earlier than handleSearch state
        setTimeout(() => {
          if (isSelected && isMultiSelect)
            setGraphState(prevState => ({
              ...prevState,
              selection: { ...prevState.selection, [organization.id]: true },
            }))
          else if (isSelected)
            setGraphState(prevState => ({
              ...prevState,
              selection: { [organization.id]: true },
            }))
        }, 0)
      }

      setIsLoading(false)
    },
    [setIsLoading, client, handleAppendItems, setGraphState],
  )

  const handleExpandKnowledge = useCallback(
    async (node: MainSchema.KnowledgeGraphNode) => {
      setIsLoading(true)
      try {
        const nodes = await onKnowledgeNodeDoubleClick(
          client,
          node,
          communityId,
        )
        if (nodes && nodes.length > 0) {
          handleAppendItems({ knowledge: nodes })
        }
        setIsLoading(false)
      } finally {
        setIsLoading(false)
      }
    },
    [client, communityId, handleAppendItems, setIsLoading],
  )

  const handleExpandAreaOfExperience = useCallback(
    async (node: IAreaOfExperienceNode) => {
      handleAppendItems({ skills: node.skills })
    },
    [handleAppendItems],
  )

  const handleCommunitySearch = useCallback(
    async (item: IItem) => {
      const innerOptions: IOptions = {
        users: [],
        skills: [],
        organizations: [],
        communities: [],
        tags: [],
        knowledge: [],
        explore: [],
      }

      const filteredCommunities = item.communities?.filter(
        community => community.id !== communityId,
      )
      const communityIds =
        filteredCommunities?.map(community => community.id) || []

      // maybe better than adding all searched communities to the item options?
      // const result = await loadPerson({
      //   variables: {
      //     userId: item.id,
      //     communityIds: [...communityIds],
      //   },
      // })

      if (filteredCommunities?.length) {
        innerOptions.communities.push(...communityIds)
        handleAppendItems({
          communities: filteredCommunities.map(community => ({
            id: community.id,
            name: community.name,
            photoUrl: community.photoUrl,
          })),
        })
      }

      if (item?.type === SEARCH_TYPES.skill) {
        innerOptions.skills.push(item.id)
        handleAppendItems({ skills: [{ id: item.id, name: item.label }] })
      }

      if (item?.type === SEARCH_TYPES.community) {
        innerOptions.communities.push(item.id)
        handleAppendItems({
          communities: [
            { id: item.id, name: item.label, photoUrl: item.photoUrl },
          ],
        })
      }

      if (item?.type === SEARCH_TYPES.organization) {
        handleAddOrganization({
          id: item.id,
        })
      }

      if (item?.type === SEARCH_TYPES.knowledge) {
        innerOptions.knowledge.push(item.id)
        handleAppendItems({
          knowledge: [
            {
              id: item.id,
              valueString: item.label,
              kind: KnowledgeGraphNodeKind.Topic,
            },
          ],
        })
      }

      if (item?.type === SEARCH_TYPES.user) {
        handleSearch({
          limit: 1,
          users: [item.id],
          communities: communityIds,
        })
      }

      if (
        item?.type === SEARCH_TYPES.role ||
        item?.type === SEARCH_TYPES.event ||
        item?.type === SEARCH_TYPES.project ||
        item?.type === SEARCH_TYPES.group ||
        item?.type === SEARCH_TYPES.custom
      ) {
        innerOptions.tags.push(item.id)
        handleAppendItems({
          tags: [{ id: item.id, name: item.label, kind: item.type }],
        })
      }

      setOptions?.({ ...options, ...innerOptions })
    },
    [
      communityId,
      handleAppendItems,
      handleAddOrganization,
      handleSearch,
      options,
      setOptions,
    ],
  )

  const handleSearchByDegrees = useCallback(
    async ({
      communityUserId,
      degrees,
    }: {
      communityUserId: string
      degrees: number
    }) => {
      if (!communityId) {
        return
      }

      setIsLoading(true)

      const result = await client.query<
        Pick<MainSchema.Query, 'communityUserConnectionsByDegrees'>,
        MainSchema.QueryCommunityUserConnectionsByDegreesArgs
      >({
        query: communityUserConnectionsByDegreesQuery,
        fetchPolicy: 'network-only',
        variables: {
          communityUserId,
          communityId,
          degrees,
        },
      })

      const connectionsByDegrees = result?.data
        ?.communityUserConnectionsByDegrees as IGraphPersonNode[]

      setIsLoading(false)

      setGraphState(prevState => ({
        ...prevState,
        appendUsers: connectionsByDegrees || [],
      }))
    },
    [client, communityId, setGraphState, setIsLoading],
  )

  const handleLoadPath = useCallback(
    async (communityUserId?: string) => {
      if (!communityUserId || !communityId) return []

      const result = await client.query<
        Pick<MainSchema.Query, 'communityPathToCommunityUser'>,
        MainSchema.QueryCommunityPathToCommunityUserArgs
      >({
        query: communityPathToCommunityUserQuery,
        variables: {
          communityId,
          communityUserId,
        },
      })

      const path = result.data?.communityPathToCommunityUser

      return isEmpty(path) ? [] : path
    },
    [client, communityId],
  )

  const handleFindPath = useCallback(
    async (communityUserId?: string) => {
      const userPaths = (await handleLoadPath(
        communityUserId,
      )) as IGraphPersonNode[]

      if (userPaths?.length > 0) {
        setPaths([userPaths])
      } else {
        setPaths([])
      }
    },
    [handleLoadPath, setPaths],
  )
  // TODO: fix
  const handleAddUserById = useCallback(
    async ({
      userId,
      isSelected = false,
      isMultiSelect = false,
      fromCommunityUserId,
    }: {
      userId: string
      isSelected?: boolean
      isMultiSelect?: boolean
      fromCommunityUserId?: string
    }) => {
      await handleSearch({ limit: 1, users: [userId] })

      if (fromCommunityUserId) {
        // Add fake connection to the graph
        handleTemporaryConnectUser({
          fromCommunityUserId,
          toCommunityUserId: userId,
        })
      }

      // Using timeout here to have an updated state that is handled by handleSearch, this has impact on updateUserSelectionAndActions at useAction.js hook,
      // Without using timeout updateUserSelectionAndActions will have an outdated state because the selection will be updated earlier than handleSearch state
      setTimeout(() => {
        if (isSelected && isMultiSelect)
          setGraphState(prevState => ({
            ...prevState,
            selection: { ...prevState.selection, [userId]: true },
          }))
        else if (isSelected)
          setGraphState(prevState => ({
            ...prevState,
            selection: { [userId]: true },
          }))
      }, 0)
    },
    [handleSearch, setGraphState, handleTemporaryConnectUser],
  )

  const handleAddUsersById = useCallback(
    async ({
      userIds,
      forceLayoutReset = true,
    }: {
      userIds: string[]
      forceLayoutReset?: boolean
    }) => {
      if (userIds.length === 0) {
        return
      }

      await handleSearch({
        limit: userIds.length,
        users: userIds,
      })

      setGraphState(prevState => ({
        ...prevState,
        forceLayoutReset,
      }))
    },
    [handleSearch, setGraphState],
  )

  const handleMyNetwork = useCallback(
    (degrees: number) => {
      const connectedUsers = keys(me?.connections || [])

      // No need to perform complex search for 1st degrees, we already know the Ids
      if (connectedUsers?.length && degrees === 1) {
        handleSearch({
          limit: connectedUsers.length,
          users: connectedUsers,
        }).then()
      } else if (connectedUsers.length) {
        handleSearchByDegrees({
          communityUserId: me?.communityUserId!,
          degrees,
        }).then()
      }
    },
    [me, handleSearch, handleSearchByDegrees],
  )

  const handleLoadAllUsers = useCallback(
    async (
      communityIds: string[],
      filter?: {
        askOfferStatementKind?: AskOfferStatementKind
      },
      setSavedLoadGraphState?: (
        value: React.SetStateAction<LoadGraphState | undefined>,
      ) => void,
      baseGraphState?: IGraphState,
    ) => {
      if (!communityIds?.length) {
        return
      }

      setIsLoading(true)

      const limit = 1000
      const { askOfferStatementKind } = filter || {}

      // load the first page to get the total pages that need to be loaded
      const result = await loadPeople({
        variables: {
          communityIds,
          limit,
          page: 0,
          askOfferStatementKind,
        },
      })

      const { userNodes, userEdges } = handleAppendItems({
        users: (result?.data?.graphPeople?.nodes as IGraphPersonNode[]) || [],
      })

      if (setSavedLoadGraphState) {
        setSavedLoadGraphState(
          prevState =>
            ({
              ...baseGraphState,
              ...prevState,
              forceLayoutReset: true,
              nodes: { ...prevState?.nodes, ...userNodes },
              edges: { ...prevState?.edges, ...userEdges },
            }) as LoadGraphState,
        )
      }

      if (result?.data?.graphPeople?.pages) {
        // create the queue with a concurrency limit
        const requestQueue = new PQueue({ concurrency: 5 })

        // generate the requests for each page and add them to the queue
        for (
          let currentPage = 1;
          currentPage <= result?.data?.graphPeople?.pages || 0;
          currentPage += 1
        ) {
          requestQueue.add(async () => {
            try {
              const result = await loadPeople({
                variables: {
                  communityIds,
                  limit,
                  page: currentPage,
                  askOfferStatementKind,
                },
              })

              handleAppendItems({
                users:
                  (result?.data?.graphPeople?.nodes as IGraphPersonNode[]) ||
                  [],
              })

              if (setSavedLoadGraphState) {
                setSavedLoadGraphState(
                  prevState =>
                    ({
                      ...baseGraphState,
                      ...prevState,
                      forceLayoutReset: true,
                      nodes: { ...prevState?.nodes, ...userNodes },
                      edges: { ...prevState?.edges, ...userEdges },
                    }) as LoadGraphState,
                )
              }
            } catch {
              // TODO: reattempt?
            }
          })
        }

        await requestQueue.onIdle()
      }

      setGraphState(prevState => ({
        ...prevState,
        forceLayoutReset: true,
      }))

      setIsLoading(false)
    },
    [setIsLoading, loadPeople, handleAppendItems, setGraphState],
  )

  const handleLoadAllTags = useCallback(async () => {
    if (!communityId) {
      return
    }

    setIsLoading(true)

    // Our backend forces a limit of 100, throws error otherwise.
    const limit = 100

    const result = await loadTags({
      communityIds: [communityId],
      kinds: {
        [NODE_KIND.custom]: true,
        [NODE_KIND.event]: true,
        [NODE_KIND.group]: true,
        [NODE_KIND.project]: true,
        [NODE_KIND.role]: true,
      },
      limit,
      page: 0,
    })

    handleAppendItems({
      tags:
        (result?.nodes?.map(tag => ({
          id: tag?.tagId,
          name: tag?.tag?.name,
          kind: tag?.tag?.kind,
        })) as ITagNode[]) || [],
    })

    if (result?.pages) {
      // create the queue with a concurrency limit
      const requestQueue = new PQueue({ concurrency: 5 })

      // generate the requests for each page and add them to the queue
      for (
        let currentPage = 1;
        currentPage <= result?.pages;
        currentPage += 1
      ) {
        requestQueue.add(async () => {
          try {
            const result = await loadTags({
              communityIds: [communityId],
              kinds: {
                [NODE_KIND.custom]: true,
                [NODE_KIND.event]: true,
                [NODE_KIND.group]: true,
                [NODE_KIND.project]: true,
                [NODE_KIND.role]: true,
              },
              limit,
              page: currentPage,
            })

            handleAppendItems({
              tags:
                (result?.nodes?.map(tag => ({
                  id: tag?.tagId,
                  name: tag?.tag?.name,
                  kind: tag?.tag?.kind,
                })) as ITagNode[]) || [],
            })
          } catch {
            // TODO: reattempt?
          }
        })
      }

      await requestQueue.onIdle()

      /* Commenting out for now
      setGraphState(prevState => ({
        ...prevState,
        forceLayoutReset: true,
      }))
      */
    }
    setIsLoading(false)
  }, [communityId, handleAppendItems, loadTags, setIsLoading])

  async function handleExpandSearchQuery(searchQuery: string) {
    setIsLoading(true)
    try {
      const newNode: IExploreNode = {
        id: `explore-${Date.now()}`,
        label: searchQuery,
        kind: NODE_KIND.explore,
      }

      const result = await client.query<
        Pick<MainSchema.Query, 'queryExplorer'>,
        MainSchema.QueryQueryExplorerArgs
      >({
        query: queryExplorer,
        fetchPolicy: 'network-only',
        variables: {
          communityIds: community?.id ? [community.id] : [],
          query: searchQuery,
          count: 10,
        },
      })

      const explorerNodes = result.data.queryExplorer.nodes.map(node => {
        return {
          ...node,
          kind: NODE_KIND.explore,
        }
      })

      const userIds = result.data.queryExplorer.people
        .flatMap(item => item.result)
        .map(scoredUser => scoredUser.communityUser.userId)

      const graphResult = await loadPeople({
        variables: {
          communityIds: communityId ? [communityId] : [],
          userIds,
          limit: userIds.length,
        },
      })

      const users =
        (graphResult?.data?.graphPeople?.nodes as IGraphPersonNode[]) || []
      const topUsers = users.slice(0, 3)

      handleAppendItems({
        exploreQuery: [newNode],
        explore: explorerNodes,
        users: topUsers,
      })
    } finally {
      setIsLoading(false)
    }
  }

  useEventBusSubscribe(EventBus.actions.graph.expandUser, handleExpandUser)
  useEventBusSubscribe(
    EventBus.actions.graph.exploreQuery,
    handleExpandSearchQuery,
  )
  useEventBusSubscribe(
    EventBus.actions.graph.expandKnowledge,
    handleExpandKnowledge,
  )
  useEventBusSubscribe(
    EventBus.actions.graph.expandAreaOfExperience,
    handleExpandAreaOfExperience,
  )
  useEventBusSubscribe(EventBus.actions.graph.findPath, handleFindPath)
  useEventBusSubscribe(EventBus.actions.graph.search, handleSearch)
  useEventBusSubscribe(EventBus.actions.search.community, handleCommunitySearch)
  useEventBusSubscribe(EventBus.actions.graph.addUserById, handleAddUserById)
  useEventBusSubscribe(EventBus.actions.graph.addUsersById, handleAddUsersById)
  useEventBusSubscribe(
    EventBus.actions.graph.addOrganizationById,
    handleAddOrganization,
  )

  useEventBusSubscribe(
    EventBus.actions.graph.expandCommunity,
    handleLoadAllUsers,
  )

  return {
    handleSearch,
    handleLoadAllUsers,
    handleLoadAllTags,
    handleMyNetwork,
    handleCommunitySearch,
  }
}

import React, {
  memo,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { useApolloClient } from '@apollo/client'
import { useFeatureFlag } from 'Features/FeatureFlags/useFeatureFlag'
import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
import { useGraphLoaders } from 'Features/GraphNodes/useGraphLoader'
import { CONTEXT_GRAPH_SNAPSHOTS_FIELD } from 'Features/GraphSnapshot/GraphSnapshotContextMenu'
import CreateGraphSnapshotModal from 'Features/GraphSnapshot/Modal/CreateGraphSnapshotModal'
import DeleteGraphSnapshotModal from 'Features/GraphSnapshot/Modal/DeleteGraphSnapshotModal'
import ManageGraphSnapshotModal from 'Features/GraphSnapshot/Modal/ManageGraphSnapshotsModal'
import UpdateGraphSnapshotModal from 'Features/GraphSnapshot/Modal/UpdateGraphSnapshotModal'
import { IPinnedNode } from 'Features/PinnedNodes/usePinnedNodes'
import graphSnapshotQuery from 'GraphQL/Queries/GraphSnapshot/graphSnapshot.graphql'
import { Chart, Index, Items, Link } from 'regraph'

import keys from 'lodash/keys'

import CommunityIntroduce from 'Components/Blocks/Community/CommunityIntroduce'
import AddToCommunityModal from 'Components/Blocks/Modals/AddToCommunity'

import { QuickActionKind } from 'Constants/graph'
import {
  KEYS,
  USER_RELATIONSHIP_STRENGTH,
  UserRelationshipStrength,
} from 'Constants/ids'
import {
  AskOfferStatementKind,
  CommunityUserGraphViewKind,
} from 'Constants/mainGraphQL'

import { useAppContext, useCommunityContext, useEntityModal } from 'Hooks'
import {
  GraphContext,
  IGraphContext,
  IGraphState,
  IShowGraphMenu,
  ISubGraph,
  LoadGraphState,
} from 'Hooks/useGraphContext'

import { DEFAULT_LAYOUT, DEFAULT_POSITIONS, DEFAULT_VIEW } from './constants'
import useAction from './useAction'
import useGraphHandlers from './useGraphHandlers'
import useGraphMapper from './useGraphMapper'
import useGraphQuery from './useGraphQuery'
import useKeysDown from './useKeysDown'
import utils, { AnalyzerFunction, COMBO_PROPERTIES, IItemData } from './utils'

import snapshotUtils from '../../../Features/GraphSnapshot/utils'
import UpdateContactModal from '../Modals/UpdateContactModal/UpdateContactModal'

export interface IOptions {
  users: string[]
  skills: string[]
  organizations: string[]
  communities: string[]
  tags: string[]
  knowledge: string[]
  explore: string[]
}

export interface IGraphProvider extends PropsWithChildren {
  graphControls?: {
    reset?: boolean
    options?: boolean
    myNetwork?: boolean
    search?: boolean
    browse?: boolean
  }
  options?: IOptions
  setOptions?: React.Dispatch<React.SetStateAction<IOptions | undefined>>
  quickActions?: QuickActionKind[]
  showTargetConnections?: boolean
  showTargetOrganizations?: boolean
  showTargetSkills?: boolean
  showTargetTags?: boolean
  targetUser?: IGraphPersonNode
  useQuickActions?: boolean
}

// TODO: How can we break this file up better?
function GraphProvider({
  children,
  targetUser,
  graphControls,
  options,
  setOptions,
  showTargetSkills,
  showTargetTags,
  showTargetConnections,
  showTargetOrganizations,
  quickActions = [],
  useQuickActions = false,
}: IGraphProvider) {
  const { me } = useAppContext()
  const { community } = useCommunityContext()
  const client = useApolloClient()
  const previousDefaultView = useRef<string>()
  const {
    loadPeopleData,
    loadPersonData,
    loadPeople,
    loadPerson,
    loadRelationshipEdges,
  } = useGraphLoaders()

  const [isPinnedNodesEnabled, setIsPinnedNodesEnabled] =
    useState<boolean>(false)

  const { featureEnabled } = useFeatureFlag()
  useEffect(() => {
    async function getIsPinnedNodesEnabled() {
      setIsPinnedNodesEnabled(await featureEnabled('pinned_nodes'))
    }
    getIsPinnedNodesEnabled()
  }, [featureEnabled])

  const [addToCommunityModal, addCommunityActions] =
    useEntityModal<MainSchema.CommunityUser[]>()
  const [updateContactModal, updateContactActions] =
    useEntityModal<MainSchema.CommunityUser>()
  const [createGraphSnapshotModal, createGraphSnapshotActions] =
    useEntityModal()
  const [updateGraphSnapshotModal, updateGraphSnapshotActions] =
    useEntityModal<MainSchema.GraphSnapshot>()
  const [deleteGraphSnapshotModal, deleteGraphSnapshotActions] =
    useEntityModal<MainSchema.GraphSnapshot>()
  const [manageGraphSnapshotsModal, manageGraphSnapshotsActions] =
    useEntityModal()

  const onOpenAddToCommunityModal = addCommunityActions.openModal
  const handleCloseAddToCommunityModal = addCommunityActions.closeModal

  const onUpdateContactModal = updateContactActions.openModal
  const handleUpdateContactModal = updateContactActions.closeModal

  const onCreateGraphSnapshotModal = createGraphSnapshotActions.openModal
  const handleCloseCreateGraphSnapshotModal =
    createGraphSnapshotActions.closeModal

  const onUpdateGraphSnapshotModal = updateGraphSnapshotActions.openModal
  const handleCloseUpdateGraphSnapshotModal =
    updateGraphSnapshotActions.closeModal

  const onDeleteGraphSnapshotModal = deleteGraphSnapshotActions.openModal
  const handleCloseDeleteGraphSnapshotModal =
    deleteGraphSnapshotActions.closeModal

  const onManageGraphSnapshotsModal = manageGraphSnapshotsActions.openModal
  const handleCloseManageGraphSnapshotsModal =
    manageGraphSnapshotsActions.closeModal

  const [paths, setPaths] = useState<IGraphPersonNode[][]>([])
  const [subGraphs, setSubGraphs] = useState<ISubGraph[]>([])
  const [showGraphMenu, setShowGraphMenu] = useState<IShowGraphMenu | null>(
    null,
  )
  const [showContextMenuIds, setShowContextMenuIds] = useState<string[]>([])
  const [nodes, setNodes] = useState<Items<IItemData>>({})
  const [edges, setEdges] = useState<Index<Link<IItemData>>>({})

  const [currentAnalyzer, setCurrentAnalyzer] =
    useState<AnalyzerFunction | null>(null)
  const [showRelationshipStrength, setShowRelationshipStrength] = useState<
    Record<string, boolean>
  >({})

  // TODO: Load initial state from user settings
  const [clusteringEnabled, setClusteringEnabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const initialLayout = useMemo<Chart.LayoutOptions>(() => {
    return {
      ...DEFAULT_LAYOUT,
      top: targetUser?.userId || me?.userId || undefined,
    }
  }, [targetUser?.userId, me?.userId])

  const getBaseGraphState = useMemo<IGraphState>(() => {
    setCurrentAnalyzer(null)
    setClusteringEnabled(false)
    setShowRelationshipStrength({})
    return {
      analyzerFunction: null,
      appendUsers: [],
      clusteringEnabled: false,
      combine: {
        properties: COMBO_PROPERTIES,
        level: 0,
      },
      firstLoad: true,
      items: {},
      layout: initialLayout,
      openCombos: {},
      positions: DEFAULT_POSITIONS,
      selection: {},
      setClusteringEnabled,
      setShowRelationshipStrength,
      showRelationshipStrength: {},
      view: DEFAULT_VIEW,
    }
  }, [initialLayout])

  // Regraph examples, documentations and their recommendation suggested merging state, so multiple state changes can be applied at same time.
  const [graphState, setGraphState] = useState<IGraphState>(getBaseGraphState)

  const { loadPinnedNodes } = useGraphLoaders()
  const [pinnedNodes, setPinnedNodes] = useState<IPinnedNode[]>([])

  const [memoizedRelationshipEdges, setMemoizedRelationshipEdges] = useState<
    Record<string, Record<string, UserRelationshipStrength>>
  >({})

  const selectedIds = useMemo(
    () => keys(graphState.selection),
    [graphState.selection],
  )

  const loadGraphState = useMemo<LoadGraphState>(
    () => ({
      clusteringEnabled: graphState.clusteringEnabled,
      showRelationshipStrength: graphState.showRelationshipStrength,
      analyzerFunction: graphState.analyzerFunction,
      nodes,
      edges,
      layout: graphState.layout,
      positions: graphState.positions,
      selection: graphState.selection,
      combine: graphState.combine,
      openCombos: graphState.openCombos,
    }),
    [
      graphState.clusteringEnabled,
      graphState.showRelationshipStrength,
      graphState.analyzerFunction,
      nodes,
      edges,
      graphState.layout,
      graphState.positions,
      graphState.selection,
      graphState.combine,
      graphState.openCombos,
    ],
  )

  const isFilteringByRelationship = useMemo(
    () =>
      showRelationshipStrength[USER_RELATIONSHIP_STRENGTH.WEAK] ||
      showRelationshipStrength[USER_RELATIONSHIP_STRENGTH.MODERATE] ||
      showRelationshipStrength[USER_RELATIONSHIP_STRENGTH.STRONG] ||
      false,
    [showRelationshipStrength],
  )

  const [currentGraphSnapshotId, setCurrentGraphSnapshotId] = useState<string>(
    CONTEXT_GRAPH_SNAPSHOTS_FIELD.RECENTLY_ADDED,
  )
  const [savedLoadGraphState, setSavedLoadGraphState] =
    useState<LoadGraphState>()

  // TODO: Add control+z or command+z for undo
  const keysDown = useKeysDown()

  const isHandMode = useMemo(
    () => !(keysDown.includes(KEYS.SHIFT) || keysDown.includes(KEYS.META)),
    [keysDown],
  )

  const [myUserNodes, myUserEdges] = useMemo(
    () =>
      loadPersonData &&
      currentGraphSnapshotId !== CONTEXT_GRAPH_SNAPSHOTS_FIELD.ASKS
        ? utils.appendItems({
            activeCommunityUserId: me?.communityUsers?.find(
              e => e.communityId === community?.id,
            )?.id,
            // This is loading ME into the graph on load
            users: [loadPersonData],
            showTargetSkills: false,
            showTargetTags: true,
            showTargetOrganizations: true,
            selectedIds: [],
          })
        : [{}, {}],
    [currentGraphSnapshotId, community?.id, loadPersonData, me?.communityUsers],
  )

  const [targetUserNodes, targetUserEdges] = useMemo(
    () =>
      targetUser
        ? utils.appendItems({
            activeCommunityUserId: targetUser?.communityUsers?.find(
              e => e.communityId === community?.id,
            )?.communityUserId,
            users: [targetUser],
            showTargetSkills,
            showTargetTags,
            showTargetOrganizations,
            selectedIds: [],
          })
        : [{}, {}],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [me, showTargetOrganizations, showTargetSkills, showTargetTags, targetUser],
  )

  const {
    introduceTo,
    availableQuickActions,
    handleIntroduceToCancel,
    handleIntroduceTo,
  } = useAction({
    selectedIds,
    quickActions,
    graphState,
    onOpenAddToCommunityModal,
    onUpdateContactModal,
    onCreateGraphSnapshotModal,
    onUpdateGraphSnapshotModal,
    onDeleteGraphSnapshotModal,
    onManageGraphSnapshotsModal,
  })

  const getDefaultViewId = useMemo(
    () =>
      me?.userCommunitySettings?.find(ucs => ucs.communityId === community?.id)
        ?.defaultGraphViewId || CONTEXT_GRAPH_SNAPSHOTS_FIELD.RECENTLY_ADDED,
    [me, community?.id],
  )

  const getDefaultViewKind = useMemo(
    () =>
      me?.userCommunitySettings?.find(ucs => ucs.communityId === community?.id)
        ?.defaultGraphViewKind,
    [me, community?.id],
  )

  // Core context items
  // Mapping handlers for generating data for regraph
  const graphMapper = useGraphMapper({
    initialLayout,
    nodes,
    edges,
    currentAnalyzer,
    showRelationshipStrength,
    targetUser,
    memoizedRelationshipEdges,
    paths,
    subGraphs,
    graphState,
    isFilteringByRelationship,
    targetUserNodes,
    targetUserEdges,
    myUserNodes,
    myUserEdges,
    savedLoadGraphState,
    onSetNodes: setNodes,
    onSetEdges: setEdges,
    onSetCurrentAnalyzer: setCurrentAnalyzer,
    onSetShowRelationshipStrength: setShowRelationshipStrength,
    onSetSubGraphs: setSubGraphs,
    onSetGraphState: setGraphState,
    onSetShowGraphMenu: setShowGraphMenu,
    onSetContextMenuIds: setShowContextMenuIds,
    setClusteringEnabled,
    setShowRelationshipStrength,
    pinnedNodes,
    onSetPinnedNodes: setPinnedNodes,
  })

  // Regraph only handlers
  const graphHandler = useGraphHandlers({
    targetUser,
    initialLayout,
    nodes,
    edges,
    graphState,
    onSetPaths: setPaths,
    onSetGraphState: setGraphState,
    onSetShowGraphMenu: setShowGraphMenu,
    onSetContextMenuIds: setShowContextMenuIds,
  })

  const graphQuery = useGraphQuery({
    options,
    setOptions,
    setPaths,
    setIsLoading,
    setGraphState,
    handleAppendItems: graphMapper.handleAppendItems,
    handleTemporaryConnectUser: graphMapper.handleTemporaryConnectUser,
  })

  // Enable/Disable clustering when setting changes, we need to wipe positions and triggers relayout
  useEffect(() => {
    setGraphState(prevState => ({
      ...prevState,
      clusteringEnabled,
      combine: {
        ...prevState.combine,
        properties: COMBO_PROPERTIES,
        level: clusteringEnabled ? 3 : 0,
      },
      positions: DEFAULT_POSITIONS,
      layout: initialLayout,
    }))
    // including initialLayout causes too many relayouts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clusteringEnabled, setGraphState])

  // Initialize basic graph on initial load
  useEffect(() => {
    if (showTargetConnections && targetUser) {
      const targetUserConnections = keys(targetUser?.connections || {})
      if (targetUserConnections.length)
        graphQuery
          .handleSearch({
            limit: targetUserConnections.length || 1,
            users: targetUserConnections,
          })
          .then()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targetUser])

  useEffect(() => {
    if (
      currentGraphSnapshotId === CONTEXT_GRAPH_SNAPSHOTS_FIELD.RECENTLY_ADDED
    ) {
      setGraphState(prevState => ({
        ...prevState,
        appendUsers: loadPeopleData?.nodes || [],
      }))
    }
  }, [loadPeopleData, currentGraphSnapshotId])

  const memoizedContext = useMemo<IGraphContext>(
    () => ({
      isLoading,
      setIsLoading,
      isHandMode,
      useQuickActions,
      graphState,
      setGraphState,
      showGraphMenu,
      showContextMenuIds,
      setShowContextMenuIds,
      subGraphs,
      graphControls,
      graphMapper,
      graphHandler,
      graphQuery,
      quickActions,
      availableQuickActions,
      currentGraphSnapshotId,
      setCurrentGraphSnapshotId,
      loadGraphState,
      setSavedLoadGraphState,
      options,
    }),
    [
      isLoading,
      setIsLoading,
      isHandMode,
      useQuickActions,
      graphState,
      showGraphMenu,
      showContextMenuIds,
      setShowContextMenuIds,
      subGraphs,
      graphControls,
      graphMapper,
      graphHandler,
      graphQuery,
      quickActions,
      availableQuickActions,
      currentGraphSnapshotId,
      setCurrentGraphSnapshotId,
      loadGraphState,
      setSavedLoadGraphState,
      options,
    ],
  )

  useEffect(() => {
    const fetchPinnedNodes = async () => {
      if (me?.userId && isPinnedNodesEnabled) {
        const result = await loadPinnedNodes()
        setPinnedNodes(result?.data.getPinnedNodes || [])
      }
    }
    fetchPinnedNodes()
  }, [me?.userId, loadPinnedNodes, isPinnedNodesEnabled])

  useEffect(() => {
    // Background load the top 25 people in the community
    if (
      community?.id &&
      currentGraphSnapshotId === CONTEXT_GRAPH_SNAPSHOTS_FIELD.RECENTLY_ADDED
    ) {
      loadPeople({
        variables: {
          communityIds: [community?.id],
          limit: 25,
          orderBy: {
            direction: 'desc',
            order: 'createdAt',
          },
        },
      })
    }

    // Background load relationship edges
    const fetchGraphRelationshipEdges = async () => {
      if (community?.id) {
        const result = await loadRelationshipEdges({
          communityIds: [community?.id],
        })
        setMemoizedRelationshipEdges(result?.edges || {})
      }
    }

    fetchGraphRelationshipEdges()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community?.id, loadPeople])

  useEffect(() => {
    if (community?.id && me?.userId) {
      loadPerson({
        variables: {
          communityId: community?.id!,
          userId: me?.userId,
          communityUserId: me?.communityUserId,
        },
      })
    }
  }, [
    community?.id,
    loadPeople,
    loadPerson,
    loadPersonData,
    me?.communityUserId,
    me?.userId,
  ])

  useEffect(() => {
    if (
      currentGraphSnapshotId === CONTEXT_GRAPH_SNAPSHOTS_FIELD.RECENTLY_ADDED
    ) {
      setNodes(myUserNodes)
      setEdges(myUserEdges)
      setSubGraphs([])
      setSavedLoadGraphState(undefined)
      setGraphState(prevState => ({
        ...prevState,
        ...getBaseGraphState,
        forceLayoutReset: true,
      }))
      const { userNodes, userEdges } = graphMapper.handleAppendItems({
        users: loadPeopleData?.nodes,
      })
      setSavedLoadGraphState(prevState => ({
        ...prevState,
        ...getBaseGraphState,
        forceLayoutReset: true,
        nodes: { ...userNodes, ...myUserNodes },
        edges: { ...userEdges, ...myUserEdges },
      }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentGraphSnapshotId,
    getBaseGraphState,
    loadPeopleData?.nodes,
    myUserNodes,
    myUserEdges,
  ])

  useEffect(() => {
    if (currentGraphSnapshotId === CONTEXT_GRAPH_SNAPSHOTS_FIELD.ASKS) {
      setNodes({})
      setEdges({})
      setSavedLoadGraphState(undefined)
      setGraphState(prevState => ({
        ...prevState,
        ...getBaseGraphState,
        forceLayoutReset: true,
      }))
      graphQuery.handleLoadAllUsers(
        [community?.id!],
        { askOfferStatementKind: AskOfferStatementKind.Ask },
        setSavedLoadGraphState,
        getBaseGraphState,
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentGraphSnapshotId, community?.id, getBaseGraphState])

  useEffect(() => {
    if (previousDefaultView.current !== getDefaultViewId) {
      previousDefaultView.current = getDefaultViewId

      const fetchData = async (id: string) => {
        const graphSnapshotResult = await client.query({
          query: graphSnapshotQuery,
          variables: {
            id,
          },
        })
        const graphSnapshot = graphSnapshotResult.data?.graphSnapshot

        if (!graphSnapshot) {
          throw new Error('Graph snapshot not found')
        }

        const loadGraphState =
          await snapshotUtils.generateLoadGraphStateFromGraphSnapshotState(
            community?.id || '',
            {
              json: graphSnapshot.state,
              version: graphSnapshot.stateVersion,
            },
            me?.userId || '',
          )
        setSavedLoadGraphState(loadGraphState)
        graphMapper.handleLoadGraphState(loadGraphState)
      }

      setCurrentGraphSnapshotId(getDefaultViewId)
      try {
        if (getDefaultViewKind === CommunityUserGraphViewKind.SavedGraph) {
          fetchData(getDefaultViewId || '')
        }
      } finally {
        setIsLoading(false)
      }
    }
  }, [
    community?.id,
    getDefaultViewId,
    getDefaultViewKind,
    me?.userId,
    client,
    graphMapper,
    pinnedNodes,
  ])

  useEffect(() => {
    if (isPinnedNodesEnabled && community?.id && currentGraphSnapshotId) {
      graphMapper.handleAppendItems({
        users: pinnedNodes.map(node => node.entity as IGraphPersonNode),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinnedNodes, isPinnedNodesEnabled, community?.id, currentGraphSnapshotId])

  return (
    <GraphContext.Provider value={memoizedContext}>
      {children}
      {introduceTo.isOpen && (
        <CommunityIntroduce
          userTo={introduceTo.userTo}
          userWhom={introduceTo.userWhom}
          onCancel={handleIntroduceToCancel}
          onSearchSelect={handleIntroduceTo}
        />
      )}
      <AddToCommunityModal
        isOpen={addToCommunityModal.isOpen}
        users={addToCommunityModal?.entity}
        onClose={handleCloseAddToCommunityModal}
      />
      {updateContactModal.isOpen && (
        <UpdateContactModal
          communityUserId={updateContactModal?.entity?.communityUserId}
          isOpen={updateContactModal.isOpen}
          onClose={handleUpdateContactModal}
        />
      )}
      <CreateGraphSnapshotModal
        isOpen={createGraphSnapshotModal.isOpen}
        onClose={handleCloseCreateGraphSnapshotModal}
      />
      {updateGraphSnapshotModal.entity && (
        <UpdateGraphSnapshotModal
          graphSnapshot={updateGraphSnapshotModal.entity}
          isOpen={updateGraphSnapshotModal.isOpen}
          onClose={handleCloseUpdateGraphSnapshotModal}
        />
      )}
      {deleteGraphSnapshotModal.entity && (
        <DeleteGraphSnapshotModal
          graphSnapshot={deleteGraphSnapshotModal.entity}
          isOpen={deleteGraphSnapshotModal.isOpen}
          onClose={handleCloseDeleteGraphSnapshotModal}
        />
      )}
      <ManageGraphSnapshotModal
        isOpen={manageGraphSnapshotsModal.isOpen}
        onClose={handleCloseManageGraphSnapshotsModal}
      />
    </GraphContext.Provider>
  )
}

export default memo(GraphProvider)

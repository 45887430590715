import React from 'react'

import Regraph, { Chart, Glyph } from 'regraph'
import { neighbors } from 'regraph/analysis'

import forEach from 'lodash/forEach'
import has from 'lodash/has'
import keys from 'lodash/keys'
import mapValues from 'lodash/mapValues'
import pickBy from 'lodash/pickBy'

import { ItemType, NodeKind } from 'Constants/graph'

import useAppContext from 'Hooks/useAppContext'
import useCommunity from 'Hooks/useCommunity'

import { theme } from 'Theme'

import { AnalyzerFunction } from './Helpers/AnalyzersFunction'
import { isCombo } from './Helpers/isCombo'
import { isEdge } from './Helpers/isEdge'
import {
  DEFAULT_DATA,
  DEFAULT_LAYOUT,
  GRAPH_ANIMATION_TIME,
  LayoutType,
} from './constants'
import { IData, ISubGraph } from './IRegraphContext'
import { useRegraphContext } from './useRegraphContext'
import useRegraphLoaders from './useRegraphLoaders'

export interface IRegraphHandlers {
  handleClick: Chart.onClickHandler
  handleDoubleClick: Chart.onDoubleClickHandler
  handleHover: Chart.onHoverHandler
  handleDragStart: Chart.onDragStartHandler<any>
  handleDragOver: Chart.onDragOverHandler<any>
  handleDragEnd: Chart.onDragEndHandler<any>
  handleChange: Chart.onChangeHandler
  handleItemInteraction: Chart.onItemInteractionHandler
  handleCombineNodes: Chart.onCombineNodesHandler<any>
  handleCombineLinks: Chart.onCombineLinksHandler
  handleContextMenu: Chart.onContextMenuHandler
  handleKeyDown: (event?: any) => void
  handleKeyUp: (event?: any) => void
  handleBlur: () => void
  handleViewChange: (event?: any) => void
  handleInitializeGraph: () => void
  handleLayoutGraph: (name?: LayoutType) => void
  handleResetGraph: () => void
  handleClustering?: (level: number) => void
  focusNodes: (nodeIds: string[]) => void
  handleUpdateNetworkAnalyser: (name: AnalyzerFunction | null) => void
  handleRemoveItemById: (id: string) => void
  handleRemoveItemsBySelected: () => void
  handleFilterSelected: () => void
  handleExitingSubGraph: () => void
}

const useRegraphHandlers = (): IRegraphHandlers => {
  const context = useRegraphContext()
  const { handleLoadPerson, handleLoadPeople } = useRegraphLoaders()
  const { community } = useCommunity()
  const { me } = useAppContext()
  const {
    data,
    items,
    subGraph,
    cachedAllEdges,
    keysDown,
    selection,
    positions,
    chartRef,
    setData,
    setSubGraph,
    setPositions,
    setSelection,
    setKeysDown,
    setCombine,
    setHighlightEdges,
    setIsComboOpen,
    setLayout,
    setContextMenuIds,
    setContextMenuPosition,
    setCurrentAnalyzer,
  } = context

  const dataCache = React.useMemo(
    () => ({
      ...data.communities,
      ...data.organizations,
      ...data.people,
      ...data.skills,
      ...data.tags,
      ...data.peopleEdges,
      ...data.workHistoryEdges,
      ...data.communityEdges,
      ...data.skillEdges,
      ...data.tagEdges,
    }),
    [data],
  )

  // ### Start Regraph Event Handlers
  const handleClick = React.useCallback<Chart.onClickHandler>(
    async event => {
      if (!event.id) {
        setHighlightEdges({})
        setSelection({})
        // onSetPaths([])
        setContextMenuPosition(null)
        setContextMenuIds([])
        // eslint-disable-next-line no-useless-return
        return
      }

      if (isCombo(event.id)) return

      const item = dataCache?.[event.id]
      item?.onClick({ event, context })
    },
    [
      context,
      dataCache,
      setContextMenuIds,
      setContextMenuPosition,
      setHighlightEdges,
      setSelection,
    ],
  )

  const handleDoubleClick = React.useCallback<Chart.onDoubleClickHandler>(
    event => {
      if (!event?.id) {
        return
      }

      if (event.id.startsWith('_combonode_')) {
        setIsComboOpen(prevState => ({
          ...prevState,
          [event.id!]: !prevState[event.id!],
        }))
        return
      }

      dataCache?.[event.id]?.onDoubleClick({
        event,
        context,
        handleLoadPeople,
        communityIds: me?.communities?.map(e => e.id) || [],
      })
    },
    [dataCache, context, handleLoadPeople, me?.communities, setIsComboOpen],
  )

  const handleHighlightEdges = React.useCallback(
    async (selectedItems: Regraph.Items) => {
      const nodeIds = Object.keys(selectedItems)
        .filter(e => !e.startsWith('edge') && !e.startsWith('_combonode_'))
        .map(e => e)

      // Use pre-compiled items and edges to find neighbors
      const foundNeighbors = await neighbors(
        { ...items, ...cachedAllEdges },
        nodeIds,
      )

      const newHighlightEdges: any = {}
      forEach(foundNeighbors, (neighbor, key) => {
        if (key.startsWith('edge')) {
          newHighlightEdges[key] = {
            ...neighbor,
            fade: false,
            width: 2,
          }
        }
      })

      setHighlightEdges(newHighlightEdges)
    },
    [cachedAllEdges, items, setHighlightEdges],
  )

  const handleChange = React.useCallback<Chart.onChangeHandler>(
    async event => {
      if (event.selection) {
        setSelection(event.selection)
        // Send and forget, don't await.
        handleHighlightEdges(event.selection)
      }

      if (event.positions) {
        setPositions((prevState: any) => ({
          ...prevState,
          ...event.positions,
        }))
      }
    },
    [handleHighlightEdges, setPositions, setSelection],
  )

  const handleHover = React.useCallback<Chart.onHoverHandler>(
    event => {
      // TODO: Highlighting on hover is triggering constant re-rendering of the entire graph
      if (!event.id) {
        // This only happens when event.id was assigned, and now it's not (no longer hovered)
        // Restore highlights to only selected nodes
        // handleHighlightEdges(selection)
        return
      }
      const hoveredNode = dataCache?.[event.id]
      hoveredNode?.onHover({ event, context })

      // Update edge highlights to include hovered node and any selected nodes we already have
      if (hoveredNode?.item) {
        /*
        handleHighlightEdges({
          ...selection,
          [event.id]: hoveredNode.item,
        } as Regraph.Items)
         */
      }
    },
    [dataCache, context],
  )

  const handleItemInteraction =
    React.useCallback<Chart.onItemInteractionHandler>(
      async event => {
        const updatedStyles: Record<string, Regraph.Node | Regraph.Link> = {}

        const item = dataCache?.[event.id]
        if (!item) {
          return
        }
        updatedStyles[event.id] = item?.onItemIteraction({
          event,
          context,
        })
        event.setStyle(updatedStyles)
      },
      [dataCache, context],
    )

  const handleDragStart = React.useCallback<Chart.onDragStartHandler<any>>(
    async event => {
      if (!event?.id) {
        return
      }
      dataCache?.[event.id]?.onDragStart({
        event,
        context,
      })
    },
    [dataCache, context],
  )

  // When drag ends, event shows items below the cursor
  const handleDragOver = React.useCallback<Chart.onDragOverHandler<any>>(
    async event => {
      if (!event?.id) {
        return
      }
      dataCache?.[event.id]?.onDragOver({
        event,
        context,
      })
    },
    [dataCache, context],
  )

  // TODO: add skill/tag directly if user is me
  const handleDragEnd = React.useCallback<Chart.onDragEndHandler<any>>(
    async event => {
      if (!event?.id) {
        return
      }
      dataCache?.[event.id]?.onDragEnd({
        event,
        context,
      })
    },
    [dataCache, context],
  )

  const handleCombineNodes = React.useCallback<
    Chart.onCombineNodesHandler<any>
  >(({ combo, id, setStyle, nodes }) => {
    const kind =
      combo.cluster?.split('_')?.[0] ||
      combo.clusterType?.split('_')?.[0] ||
      combo.clusterContainer?.split('_')?.[0]

    const color =
      theme.graph.nodeKind[kind as NodeKind] || theme.colors.primaryCardinal

    const text =
      combo.cluster?.split('_')?.pop() ||
      combo.clusterType?.split('_')?.pop() ||
      combo.clusterContainer?.split('_')?.pop() ||
      id

    const size = Math.sqrt(keys(nodes)?.length || 2)

    const typeGylph: Glyph = {
      angle: 180,
      radius: 45,
      label: { text, color: 'black' },
      color: 'white',
      border: {
        color,
      },
    }

    // TODO: Fix problem with state management for opening/closing combos. For now leave them all open
    setStyle({
      open: true, // !!isComboOpen[id],
      border: { color, width: 5 },
      fade: false,
      label: {
        text: '',
        fontSize: 10,
      },
      closedStyle: {
        color,
        size: Math.min(size, 10),
        glyphs: [typeGylph],
      },
    })
  }, [])

  const handleCombineLinks = React.useCallback<Chart.onCombineLinksHandler>(
    ({ setStyle }) => {
      setStyle({
        contents: false,
        width: 1,
        lineStyle: 'dashed',
      })
    },
    [],
  )

  const handleContextMenu = React.useCallback<Chart.onContextMenuHandler>(
    event => {
      event.preventDefault()

      if (event.id === null) {
        return
      }

      const item = items[event.id]

      if (item && item.data!.type === 'edge') {
        return
      }

      if (selection[event.id] === null) {
        setSelection((prevState: any) => ({
          ...prevState,
          [event.id!]: true,
        }))
      }

      setContextMenuIds([event.id])

      if (item.data!.type !== ItemType.User) {
        setContextMenuPosition({
          x: event.x,
          y: event.y + 25,
        })
      }
    },
    [items, selection, setContextMenuIds, setContextMenuPosition, setSelection],
  )

  const handleKeyDown = React.useCallback(
    (event: any) => {
      if (!keysDown?.includes(event.key)) {
        setKeysDown(prevState => [...prevState, event.key])
      }
    },
    [keysDown, setKeysDown],
  )

  const handleKeyUp = React.useCallback(
    (event: any) => {
      setKeysDown(keysDown.filter(key => key !== event.key))
    },
    [keysDown, setKeysDown],
  )

  const handleBlur = React.useCallback(() => {
    setKeysDown([])
  }, [setKeysDown])

  const handleViewChange = React.useCallback((event: Chart.ViewOptions) => {
    if (!event) {
      // eslint-disable-next-line no-useless-return
      return
    }
  }, [])

  const handleLayoutGraph = React.useCallback(
    (name?: LayoutType) => {
      if (!chartRef?.fit) {
        return
      }
      setPositions({})
      setLayout(prevState => ({
        ...prevState,
        name: name || prevState.name,
      }))
      chartRef?.fit('all')
    },
    [chartRef, setLayout, setPositions],
  )

  const handleInitializeGraph = React.useCallback(async () => {
    if (community?.id && me?.userId) {
      await handleLoadPerson({
        communityId: community?.id!,
        userId: me?.userId,
        communityUserId: me?.communityUserId,
        showTargetTags: true,
        showTargetSkills: true,
        showTargetCommunities: true,
        showTargetOrganizations: true,
        showTargetEducation: true,
        excludeShowCommunities: [community?.id],
      })
      await handleLoadPeople({
        communityIds: [community?.id],
        limit: 25,
        orderBy: { order: 'createdAt', direction: 'desc' },
      })
      handleLayoutGraph()
    }
  }, [
    community?.id,
    handleLayoutGraph,
    handleLoadPeople,
    handleLoadPerson,
    me?.communityUserId,
    me?.userId,
  ])

  const handleResetGraph = React.useCallback(() => {
    setPositions({})
    setData({
      people: {},
      tags: {},
      skills: {},
      organizations: {},
      communities: {},
      peopleEdges: {},
      tagEdges: {},
      skillEdges: {},
      workHistoryEdges: {},
      educationHistoryEdges: {},
      communityEdges: {},
      allEdges: {},
    })
    setCombine(prevState => ({
      ...prevState,
      level: 0,
    }))
    setSelection({})
    setHighlightEdges({})
    handleInitializeGraph()
    setLayout(prevState => ({
      ...prevState,
      layout: { ...DEFAULT_LAYOUT },
    }))
  }, [
    handleInitializeGraph,
    setCombine,
    setData,
    setHighlightEdges,
    setLayout,
    setPositions,
    setSelection,
  ])

  const handleClustering = React.useCallback(
    (level: number) => {
      setCombine(prevState => ({
        ...prevState,
        level,
      }))
    },
    [setCombine],
  )

  const focusNodes = React.useCallback(
    (nodeIds: string[]) => {
      if (!chartRef?.fit) {
        return
      }
      forEach(nodeIds, nodeId => {
        setTimeout(() => {
          chartRef?.fit('selection')
          chartRef?.ping(nodeId, {
            color: 'rgba(205, 28, 69, 0.6)',
            repeat: 3,
            haloWidth: 16,
          })
          // wait for graph
          // animation to finish
        }, GRAPH_ANIMATION_TIME + 1)
        setSelection((prevState: any) => ({
          ...prevState,
          [nodeId]: true,
        }))
      })
    },
    [chartRef, setSelection],
  )

  const handleUpdateNetworkAnalyser = React.useCallback(
    (name: AnalyzerFunction | null) => {
      if (!chartRef?.fit) {
        return
      }
      setCurrentAnalyzer(name)
      setPositions({})
      chartRef?.fit('all')
    },
    [chartRef, setCurrentAnalyzer, setPositions],
  )

  const processRemovalList = React.useCallback(
    (removalList: string[]) => {
      let newData = { ...data }

      function removeItemFromData(
        existingData: Record<string, any>,
        id: string,
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { [id]: _, ...rest } = existingData
        return rest
      }

      removalList.forEach(id => {
        const node = items[id]
        const itemType = node?.data?.type

        if (!itemType) return

        const typeKeyMap: Record<string, keyof typeof newData> = {
          [ItemType.User]: 'people',
          [ItemType.Tag]: 'tags',
          [ItemType.Skill]: 'skills',
          [ItemType.Organization]: 'organizations',
          [ItemType.Community]: 'communities',
        }

        const key = typeKeyMap[itemType]
        if (key) {
          newData = {
            ...newData,
            [key]: removeItemFromData(newData[key], id),
          }
        }
      })

      return newData
    },
    [data, items],
  )

  const handleRemoveItemById = React.useCallback(
    (id: string) => {
      setData(processRemovalList([id]))
    },
    [processRemovalList, setData],
  )

  const handleRemoveItemsBySelected = React.useCallback(() => {
    setData(processRemovalList(Object.keys(selection)))
  }, [processRemovalList, selection, setData])

  const handleFilterSelected = React.useCallback(() => {
    const filteredData = mapValues(data, categoryData => {
      // Filter inner objects based on their IDs being in the selection dictionary
      return pickBy(categoryData, (_, id) => {
        return !isEdge(id) && has(selection, id)
      })
    })

    const newData = {
      ...filteredData,
      peopleEdges: data.peopleEdges,
      tagEdges: data.tagEdges,
      skillEdges: data.skillEdges,
      workHistoryEdges: data.workHistoryEdges,
      educationHistoryEdges: data.educationHistoryEdges,
      communityEdges: data.communityEdges,
      allEdges: data.allEdges,
    } as IData

    const currentGraph: ISubGraph[] = subGraph
    currentGraph.push({
      savedNodes: data,
      data: {
        ...newData,
        peopleEdges: data.peopleEdges,
        tagEdges: data.tagEdges,
        skillEdges: data.skillEdges,
        workHistoryEdges: data.workHistoryEdges,
        educationHistoryEdges: data.educationHistoryEdges,
        communityEdges: data.communityEdges,
        allEdges: data.allEdges,
      } as IData,
      positions,
    })
    setSubGraph(currentGraph)
    setData(newData)
  }, [data, subGraph, positions, setSubGraph, setData, selection])

  const handleExitingSubGraph = React.useCallback(() => {
    const sub = subGraph.pop()
    if (!sub) {
      setSubGraph([])
    }

    setSubGraph(subGraph)
    setData(sub?.savedNodes || sub?.data || DEFAULT_DATA)
    setPositions(sub?.positions || {})
  }, [setData, setPositions, setSubGraph, subGraph])

  return {
    handleClick,
    handleDoubleClick,
    handleDragStart,
    handleDragOver,
    handleDragEnd,
    handleChange,
    handleHover,
    handleItemInteraction,
    handleCombineNodes,
    handleCombineLinks,
    handleContextMenu,
    handleKeyDown,
    handleKeyUp,
    handleBlur,
    handleInitializeGraph,
    handleViewChange,
    handleLayoutGraph,
    handleResetGraph,
    handleClustering,
    focusNodes,
    handleUpdateNetworkAnalyser,
    handleRemoveItemById,
    handleRemoveItemsBySelected,
    handleFilterSelected,
    handleExitingSubGraph,
  } as IRegraphHandlers
}

export default useRegraphHandlers

import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'

import forEach from 'lodash/forEach'

import { IGraphEdge } from '../Edges/BaseGraphEdge'
import { CommunityEdge } from '../Edges/CommunityEdge'
import { SkillEdge } from '../Edges/SkillEdge'
import { TagEdge } from '../Edges/TagEdge'
import { UserConnectionEdge } from '../Edges/UserConnectionEdge'
import { WorkHistoryEdge } from '../Edges/WorkHistoryEdge'
import { IGraphNode } from '../Nodes/BaseGraphNode'
import { PersonNode } from '../Nodes/PersonNode'

interface IMapPeopleHandler {
  people: IGraphPersonNode[]
  activeCommunityUserId?: string | null
  myUserId?: string | null
}

export interface IMapPeopleResult {
  newPeople: Record<string, IGraphNode>
  newConnectionEdges: Record<string, IGraphEdge>
  newTagEdges: Record<string, IGraphEdge>
  newSkillEdges: Record<string, IGraphEdge>
  newWorkHistoryEdges: Record<string, IGraphEdge>
  newCommunityEdges: Record<string, IGraphEdge>
}

export function mapPeopleToGraphHandler(
  args: IMapPeopleHandler,
): IMapPeopleResult {
  const { people, activeCommunityUserId, myUserId } = args
  const newPeople: Record<string, IGraphNode> = {}
  const newConnectionEdges: Record<string, IGraphEdge> = {}
  const newTagEdges: Record<string, IGraphEdge> = {}
  const newSkillEdges: Record<string, IGraphEdge> = {}
  const newWorkHistoryEdges: Record<string, IGraphEdge> = {}
  const newCommunityEdges: Record<string, IGraphEdge> = {}

  forEach(people, (person: IGraphPersonNode) => {
    const node = new PersonNode({
      person,
      isMe: person.userId === myUserId,
    })
    newPeople[person.userId] = node

    // Process all the edges into the cache
    forEach(person.connections, connection => {
      const edge = new UserConnectionEdge({
        id1: person.communityUserId,
        id2: connection?.toCommunityUserId!,
      })
      newConnectionEdges[edge.id] = edge
    })

    forEach(person.communityUserTags, communityUserTag => {
      if (communityUserTag?.communityUserId === activeCommunityUserId) {
        const edge = new TagEdge({
          id1: person.userId,
          id2: communityUserTag?.tagId!,
        })
        newTagEdges[edge.id] = edge
      }
    })

    forEach(person.communityUserSkills, communityUserSkill => {
      const edge = new SkillEdge({
        id1: person.userId,
        id2: communityUserSkill?.skillId!,
      })
      newSkillEdges[edge.id] = edge
    })

    forEach(person.communityUserWorkHistory, communityUserWorkHistory => {
      const edge = new WorkHistoryEdge({
        id1: person.userId,
        id2: communityUserWorkHistory?.organizationId!,
      })
      newWorkHistoryEdges[edge.id] = edge
    })

    forEach(person.communityUserEducationHistory, communityUserEducation => {
      const edge = new WorkHistoryEdge({
        id1: person.userId,
        id2: communityUserEducation?.organizationId!,
      })
      newWorkHistoryEdges[edge.id] = edge
    })

    forEach(person.communities, community => {
      const edge = new CommunityEdge({
        id1: person.userId,
        id2: community?.id!,
      })
      newCommunityEdges[edge.id] = edge
    })
  })

  return {
    newPeople,
    newConnectionEdges,
    newTagEdges,
    newSkillEdges,
    newWorkHistoryEdges,
    newCommunityEdges,
  }
}

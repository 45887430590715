import { IGraphPersonNode } from 'Features/GraphNodes/NodeTypes'
// import communityPathToUserQuery from 'GraphQL/Queries/Community/communityPathToUser.graphql'
import communityUserConnectionsByDegreesQuery from 'GraphQL/Queries/Community/communityUserConnectionsByDegrees.graphql'
import { createBrowserHistory } from 'history'

import { createApolloClient } from 'Services/Apollo'

import graphPeopleQuery from './Queries/graphPeople.graphql'

export async function loadPeopleByDegrees(
  me: MainSchema.User,
  degrees: number,
  activeCommunityId: string,
): Promise<IGraphPersonNode[]> {
  const communityIds = me?.communities?.map(e => e.id) || []
  if (!communityIds || !communityIds?.length) {
    return []
  }

  const history = createBrowserHistory()
  const client = createApolloClient({ history })

  // TODO: Optimize this into a single query
  const resultByDegrees = await client.query<
    Pick<MainSchema.Query, 'communityUserConnectionsByDegrees'>,
    MainSchema.QueryCommunityUserConnectionsByDegreesArgs
  >({
    query: communityUserConnectionsByDegreesQuery,
    variables: {
      communityId: activeCommunityId,
      communityUserId: me?.communityUserId!,
      degrees,
    },
  })
  if (!resultByDegrees?.data?.communityUserConnectionsByDegrees?.length) {
    return []
  }
  const result = await client.query<
    Pick<MainSchema.Query, 'graphPeople'>,
    MainSchema.QueryGraphPeopleArgs
  >({
    query: graphPeopleQuery,
    variables: {
      communityIds,
      userIds: resultByDegrees?.data?.communityUserConnectionsByDegrees.map(
        e => e.userId,
      ),
    },
  })
  return (result?.data?.graphPeople?.nodes as IGraphPersonNode[]) || []
}
